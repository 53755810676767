import { OpenAI } from "openai";

const openai = new OpenAI({
    organization: process.env.REACT_APP_OPEN_AI_API_ORG,
    apiKey: process.env.REACT_APP_OPEN_AI_API_KEY,
    dangerouslyAllowBrowser: true,
});

// Function to extract name using AI
const extractNameAI = async (response) => {
    const prompt = `
        Extract the person's name from the following text. If there are multiple names or nicknames, choose the most likely actual name. If no clear name is present, respond with "Unknown". Only return the name, nothing else.

        Text: "${response}"

        [Name]
    `;

    const completion = await openai.chat.completions.create({
        messages: [{ role: "system", content: prompt }],
        model: "gpt-4o-mini", // Using a smaller model for efficiency
        max_tokens: 20,
        temperature: 0.3,
    });

    return completion.choices[0].message.content.trim();
};

const generateText = async (responses) => {
    // Extract user inputs from responses
    const fullNameResponse = responses[0].answer;
    const extractedName = await extractNameAI(fullNameResponse);
    console.log("Extracted Name:", extractedName);
    const zodiacSign = responses[1].answer;
    const playlistType = responses[2].answer;
    const favoriteAlbum = responses[3].answer;
    const moonFeeling = responses[4].answer;
    const lifePriority = responses[5].answer;
    const currentState = responses[6].answer;

    // Create a structured prompt based on the user inputs
    const prompt = `
        The following are 8 moon phases and their corresponding phrases:

        1. New Moon: I am open to new beginnings.
        2. Waxing Crescent: I trust my path and take steps towards my dreams with confidence.
        3. First Quarter: I trust myself and my decisions.
        4. Waxing Gibbous: I am constantly improving, evolving, and moving closer to my true self.
        5. Full Moon: I celebrate my progress and honor my journey.
        6. Waning Gibbous: I let go of what no longer serves me with grace and gratitude.
        7. Last Quarter: I release with love and prepare for new beginnings.
        8. Waning Crescent: I surrender to the flow of life and embrace the restorative power of rest.

        Generate a response using the following inputs:
        - Extracted Name: ${extractedName}
        - Full Name Response: ${fullNameResponse}
        - Zodiac Sign: ${zodiacSign}
        - Favorite Album: ${favoriteAlbum}
        - Preferred Playlist Type: ${playlistType}
        - Moon Feeling: ${moonFeeling}
        - Life Priority: ${lifePriority}
        - Current State: ${currentState}

        Response format:
        - If the name is a real name or word, use it, even if it is in a different language and different characters.
        - If the name is offensive or a swear word, set the name as "Unknown" instead.
        - Include the moon phase at the start.
        - Incorporate the user's extracted name, zodiac sign, and the type of music they enjoy.
        - Consider their emotional response to the moon and current life priorities in the prophecy.
        - Reference their current state of mind to make the prophecy more personally meaningful.
        - Mention their favorite album in a meaningful way.
        - Limit the response to 60 words.
        - If the extracted name is "Unknown" or differs significantly from the full name response, incorporate a subtle, creative reference to this in the generated text.
        - Generally, try to use a tone that is fitting for a fortune teller or a prophecy.
        - Do not use any emojis or special characters in the response.
        - Do not use any profanities or offensive language.
        - If you do not know the music or album they mention, refer more to their zodiac sign and music taste.

        Example:

        Inputs: 
        - Extracted Name: Sarah
        - Full Name Response: My name is Sarah but my friends call me Stardust
        - Zodiac Sign: Taurus
        - Favorite Album: "Blue" by Joni Mitchell
        - Preferred Playlist Type: Chill acoustic
        - Moon Feeling: I feel calm
        - Life Priority: Personal Growth and Wellness
        - Current State: I am trying something new
        Response: Waxing Crescent: Sarah, your calm connection to the moon aligns with your journey of personal growth. As a steadfast Taurus exploring new paths, let Joni Mitchell's "Blue" be your guide. Your love for acoustic melodies reflects your soul's gentle evolution. Trust in this transformative phase.
    `;

    const completion = await openai.chat.completions.create({
        messages: [{ role: "system", content: prompt }],
        model: "gpt-4o-mini",
        max_tokens: 95,
        temperature: 0.6,
    });

    const generatedText = completion.choices[0].message.content;
    return { generatedText, extractedName };
};

export default generateText;