import React, { useEffect, useRef, useState } from 'react';
import p5 from 'p5/lib/p5.js';
import { shareImage } from '../utils/shareModule'; // Assuming you have a shareModule
import { Sheet } from 'react-modal-sheet';

const ResultsSlide = ({ responses, selectedMoon, generatedText, extractedName, resetQuestions }) => {
  const sketchRef = useRef(null);
  const [p5Instance, setP5Instance] = useState(null);





  useEffect(() => {
    if (!responses || !selectedMoon || !generatedText) return;

    // Size multiplier will impact all other values based on canvas size
    let sizeMultiplier = 0;

    let padding = 0; // For the rect frame

    let fontSizeDescription = 0; // For the playlist description 
    let fontSizeText = 0; // For everything else

    let textPadding = 0;
    let lineHeightText = 0;
    let lineHeightDescription = 0;
    let logoSize = 0;

    let bgColor = "#FFFDF3";
    let textColor = "#0a0a0a";

    const newMoonColors = ['#7EA4FF', '#A3C8FA', '#F7AB8A']; // New Moon
    const waxingCrescentColors = ['#F8D808', '#E66D7B', '#282962']; // Waxing Crescent
    const firstQuarterColors = ['#FDA2DA', '#7C47DB', '#0A26AC']; // First Quarter
    const waxingGibbousColors = ['#00B7FF', '#FEF439', '#FF3030']; // Waxing Gibbous
    const fullMoonColors = ['#FC4084', '#FDBFD6', '#395D93']; // Full Moon
    const waningGibbousColors = ['#FFDBD2', '#DC8169', '#134787']; // Waning Gibbous
    const lastQuarterColors = ['#30B1C9', '#FDDEE1', '#F9C6C8']; // Last Quarter
    const waningCrescentColors = ['#F7C6FA', '#96B9EF', '#AFF4CB']; // Waning Crescent

    let selectedMoonColors = null;


    const sketch = (p) => {
      p.checkFont = (text) => {
        if (text.charCodeAt(0) > 12000 && text.charCodeAt(0) < 40703) {
          p.textFont(p.japanese_font);
          if (text.charCodeAt(0) > 19968 && text.charCodeAt(0) < 40703) {
            p.textFont(p.chinese_font);
          }
        } else if (text.charCodeAt(0) > 40703) {
          p.textFont(p.korean_font);
        } else if (text.charCodeAt(0) > 3583 && text.charCodeAt(0) < 3711) {
          p.textFont(p.thai_font);
        } else {
          p.textFont(p.bodoniRegular); // Default font
        }
      };

      p.preload = () => {
        // TODO: Add font preload
        p.bodoniRegular = p.loadFont(`${process.env.PUBLIC_URL}/Assets/Fonts/BodoniModa_9pt-Medium.ttf`);
        p.bodoniItalic = p.loadFont(`${process.env.PUBLIC_URL}/Assets/Fonts/BodoniModa_9pt-Italic.ttf`);
        p.japanese_font = p.loadFont(`${process.env.PUBLIC_URL}/Assets/Fonts/NotoSerifJP-Regular.ttf`);
        p.chinese_font = p.loadFont(`${process.env.PUBLIC_URL}/Assets/Fonts/NotoSerifTC-Regular.ttf`);
        p.korean_font = p.loadFont(`${process.env.PUBLIC_URL}/Assets/Fonts/NotoSerifKR-Regular.ttf`);
        p.thai_font = p.loadFont(`${process.env.PUBLIC_URL}/Assets/Fonts/NotoSerifThai-Regular.ttf`);
      };

      let canvasWidth = 0;
      let canvasHeight = 0;

      p.setup = () => {
        p.pixelDensity(2);
        if (p.windowWidth < 768) {
          canvasWidth = p.windowWidth * 0.9;
          canvasHeight = canvasWidth * (16 / 9);
          sizeMultiplier = canvasWidth / 512;
        }
        else {
          canvasWidth = 512;
          canvasHeight = 512 * (16 / 9);
          sizeMultiplier = 1;
        }


        padding = canvasWidth * 0.075;
        fontSizeText = canvasWidth * 0.037;
        fontSizeDescription = canvasWidth * 0.06; // Will continue to be adjusted as we test
        lineHeightText = fontSizeText * 1.5;
        lineHeightDescription = fontSizeDescription * 1.5;


        p.createCanvas(canvasWidth, canvasHeight);

        p.textFont(p.bodoniRegular);
        p.textAlign(p.CENTER, p.CENTER);
        p.imageMode(p.CENTER);
        p.background(bgColor); // Off-white background
        let seed = Math.floor(Math.random() * 1000);
        p.randomSeed(seed);
        p.noiseSeed(seed);
        setP5Instance(p); // Store the p5 instance
        p.noLoop(); // Prevent draw from being called repeatedly
      };

      p.draw = () => {
        // Draw frame
        p.background(bgColor);
        p.rectMode(p.CORNER);
        p.stroke(textColor);
        p.strokeWeight(2);
        p.noFill();
        p.rect(padding, padding, p.width - 2 * padding, p.height - 2 * padding);

        // Draw title (Moon Phase)
        p.rectMode(p.CENTER);
        p.noStroke();
        p.fill(bgColor);
        let rectWidth = p.textWidth(selectedMoon) + 3 * padding;
        p.rect(p.width / 2, padding, rectWidth, 10);

        p.fill(textColor);
        p.noStroke();
        p.textSize(fontSizeText);
        p.textAlign(p.CENTER, p.CENTER);
        p.text(selectedMoon, p.width / 2, padding - fontSizeText / 4);

        // Draw mantra
        p.textSize(fontSizeDescription);
        p.textAlign(p.CENTER, p.CENTER);
        p.textFont(p.bodoniItalic);
        let description = p.setPhaseDescriptionAndColors(selectedMoon);
        p.text(description, p.width / 2, p.height / 8, p.width - 2.5 * padding);

        p.drawMoon(selectedMoon);

        p.noStroke();
        p.fill(textColor);
        // Draw prophecy (generated text) // Add this at around 3/5 of the height
        p.textSize(fontSizeText);
        p.textAlign(p.LEFT, p.CENTER);
        // Your MoonTeller prophecy:
        let prophecyStartY = p.height * 0.6;
        p.text("Your MoonTeller prophecy:", 1.5 * padding, prophecyStartY);

        p.drawWrappedText(generatedText, 1.5 * padding, prophecyStartY + lineHeightText, p.width - 3 * padding, lineHeightText);

        // Draw name and zodiac, probably need some error handling here for longer names and zodiacs
        p.rectMode(p.CORNER)
        p.textSize(fontSizeText * 0.8);
        p.textAlign(p.LEFT, p.CENTER);
        p.textFont(p.bodoniRegular);

        p.fill(bgColor);
        p.noStroke();
        p.checkFont(extractedName);
        let nameZodiac = extractedName + " | " + responses[1].answer;
        let nameZodiacWidth = p.textWidth(nameZodiac);
        let nameZodiacRectWidth = nameZodiacWidth + 1 * padding;
        p.rect(padding - 2, p.height - padding - 5, nameZodiacRectWidth, 10);

        let url = "www.themoonteller.com";
        let urlWidth = p.textWidth(url);
        let urlRectWidth = urlWidth + 1 * padding;
        p.rect(p.width - padding + 2, p.height - padding - 5, -urlRectWidth, 10);

        p.fill(textColor);
        p.text(extractedName + " | " + responses[1].answer, 1.5 * padding, p.height - padding * 1.125);
        
        p.textFont(p.bodoniRegular);
        p.textAlign(p.RIGHT, p.CENTER);
        p.text("www.themoonteller.com", p.width - padding - 0.5 * padding, p.height - padding * 1.125);

      };

      p.setPhaseDescriptionAndColors = (phase) => {
        switch (phase) {
          case 'New Moon':
            selectedMoonColors = newMoonColors;
            return 'I am open to new beginnings.';
          case 'Waxing Crescent':
            selectedMoonColors = waxingCrescentColors;
            return 'I trust my path and take steps towards my dreams with confidence.';
          case 'First Quarter':
            selectedMoonColors = firstQuarterColors;
            return 'I trust myself and my decisions.';
          case 'Waxing Gibbous':
            selectedMoonColors = waxingGibbousColors;
            return 'I am constantly improving, evolving, and moving closer to my true self.';
          case 'Full Moon':
            selectedMoonColors = fullMoonColors;
            return 'I celebrate my progress and honor my journey.';
          case 'Waning Gibbous':
            selectedMoonColors = waningGibbousColors;
            return 'I let go of what no longer serves me with grace and gratitude.';
          case 'Last Quarter':
            selectedMoonColors = lastQuarterColors;
            return 'I release with love and prepare for new beginnings.';
          case 'Waning Crescent':
            selectedMoonColors = waningCrescentColors;
            return 'I surrender to the flow of life and embrace the restorative power of rest.';
          default:
            return ''; // Return an empty string or a default message if the phase is not recognized
        }
      };

      p.drawWrappedText = (text, xStart, yStart, maxWidth, lineHeight) => {
        let x = xStart;
        let y = yStart;
        let processedText = text.includes(':') ? text.split(':').slice(1).join(':').trim() : text;
        processedText = processedText.replace(/\*/g, '');
        processedText = processedText.replace(/\n/g, '');

        const words = processedText.split(' ');
        let yOffset = 0; // Vertical offset for the current line

        words.forEach((word) => {
          // Check the font for the current word
          p.checkFont(word);
          const wordWidth = p.textWidth(word + ' '); // Calculate the width of the current word

          // Check if adding this word exceeds the max width
          if ((x + wordWidth) > (maxWidth + xStart)) {
            // If it exceeds, move to the next line
            yOffset += lineHeight; // Move down for the next line
            x = xStart; // Reset x position for the new line
          }

          // Draw the current word
          p.text(word, x, y + yOffset);
          x += wordWidth; // Update x position for the next word
        });
      };

      // This is not working too well, should look at how I did Nebulae, and have those rings with some fill
      p.drawMoon = (phase) => {
        let diameter = p.width - 2 * padding;
        let centerX = p.width / 2;
        let centerY = p.height * 0.5;
        let radius = diameter / 2;

        p.push();
        p.translate(centerX, centerY);

        // Create off-screen graphics for the gradient
        let gradientGraphics = p.createGraphics(diameter * 2, diameter * 2);
        gradientGraphics.noStroke();
        gradientGraphics.fill(bgColor);

        // Generate irregular shape
        let points = 12;
        let shape = [];
        for (let i = 0; i < points; i++) {
          let angle = (i / points) * p.TWO_PI;
          let r = radius / 1.5 * (0.7 + p.random(1) * 0.4); // Less variation for a more circular shape
          let x = r * p.cos(angle);
          let y = r * p.sin(angle);
          shape.push({ x, y });
        }

        // Create linear gradient
        let underlyingGradient = gradientGraphics.drawingContext.createLinearGradient(
          0, 0, diameter, diameter
        );

        let noiseVal = p.noise(p.frameCount * 0.0001);
        underlyingGradient.addColorStop(0, p.color(selectedMoonColors[0]));
        underlyingGradient.addColorStop(0.5 + noiseVal * 0.1, p.color(selectedMoonColors[1]));
        underlyingGradient.addColorStop(1, p.color(selectedMoonColors[2]));

        // Apply gradient to graphics
        gradientGraphics.drawingContext.fillStyle = underlyingGradient;

        // Draw irregular shape with gradient
        gradientGraphics.beginShape();
        for (let i = 0; i < points; i++) {
          let { x, y } = shape[i];
          gradientGraphics.curveVertex(x + radius, y + radius);
        }
        gradientGraphics.endShape(p.CLOSE);

        // Apply blur to the gradient
        gradientGraphics.filter(p.BLUR, radius / 6);

        // Draw the blurred gradient
        p.image(gradientGraphics, radius, radius / 2);

        // Add some noise texture
        p.blendMode(p.OVERLAY);
        for (let i = 0; i < 2000; i++) {
          let angle = p.random(p.TWO_PI);
          let r = p.random(radius);
          let x = r * p.cos(angle);
          let y = r * p.sin(angle);
          let noiseVal = p.noise(x * 0.05, y * 0.05) * 255;
          p.stroke(noiseVal, 30);
          p.point(x, y);
        }
        p.blendMode(p.BLEND);
        p.pop();



        // Draw a moon outline
        p.push();
        p.translate(centerX, centerY);


        p.fill(bgColor);

        p.drawingContext.shadowBlur = 100;
        p.drawingContext.shadowColor = 'rgba(255, 255, 255, 1.0)';

        // Draw the outline
        p.noFill();
        p.stroke(255);
        p.strokeWeight(radius / 50);

        if (phase !== 'New Moon') {
          p.beginShape();
          switch (phase) {
            case 'Full Moon':
              for (let a = 0; a <= p.TWO_PI; a += 0.1) {
                let x = radius / 2 * p.cos(a);
                let y = radius / 2 * p.sin(a);
                p.vertex(x, y - radius / 2);
              }
              break;
            case 'Waxing Crescent':
            case 'Waning Crescent':
              if (phase === 'Waning Crescent') {
                p.translate(-radius / 4, 0);
                p.rotate(p.random(p.PI / 8, p.PI / 4));
              }
              else {
                p.translate(radius / 3, 0);
                p.rotate(p.random(-p.PI / 16, -p.PI / 8));
              }
              let xOffset = radius * 0.25;
              let arcSpan = p.PI * 0.825;
              let extendAngle = p.atan2(radius, xOffset) * 0.18;
              p.scale(phase === 'Waning Crescent' ? -1 : 1, 1);
              drawCrescent(xOffset, arcSpan, extendAngle);
              break;
            case 'First Quarter':
            case 'Last Quarter':
              if (phase === 'Last Quarter') {
                p.translate(-radius / 8, 0);
              }
              else {
                p.translate(radius / 8, 0);
              }
              p.scale(phase === 'Last Quarter' ? -1 : 1, 1);
              drawQuarter(true);  // Always draw as if it's First Quarter, scaling will mirror it
              break;
            case 'Waxing Gibbous':
              drawGibbous(true);
              break;
            case 'Waning Gibbous':
              p.scale(phase === 'Waning Gibbous' ? -1 : 1, 1);
              drawGibbous(false);  // Always draw as if it's Waxing Gibbous, scaling will mirror it
              break;
          }
          p.endShape(p.CLOSE);
        }

        // Moon fill
        const rotateGradient = (angle) => {
          const cos = Math.cos(angle);
          const sin = Math.sin(angle);
          const cx = 0;  // center x
          const cy = -radius / 2;  // center y
          const length = radius / 2 + padding;

          return [
            cx - length * cos, cy - length * sin,  // start point
            cx + length * cos, cy + length * sin   // end point
          ];
        };

        // Generate a random angle for rotation
        const generateConstrainedAngle = () => {
          // Define the range of angles (in radians) for top-down and bottom-up orientations
          const topDownRange = [-p.PI / 2 - p.PI / 6, p.PI / 2 + p.PI / 6];  // -30 to 30 degrees
          const bottomUpRange = [3 * p.PI / 2 - p.PI / 6, 3 * p.PI / 2 + p.PI / 6];  // 150 to 210 degrees

          // Randomly choose between top-down and bottom-up
          if (Math.random() < 0.5) {
            // Top-down orientation
            return p.random(topDownRange[0], topDownRange[1]);
          } else {
            // Bottom-up orientation
            return p.random(bottomUpRange[0], bottomUpRange[1]);
          }
        };

        // Use the constrained angle
        const randomAngle = generateConstrainedAngle();

        // Get rotated gradient coordinates
        const [x1, y1, x2, y2] = rotateGradient(randomAngle);

        let moonGradient = p.drawingContext.createLinearGradient(x1, y1, x2, y2);

        moonGradient.addColorStop(0, p.color(selectedMoonColors[0]));
        moonGradient.addColorStop(0.5, p.color(selectedMoonColors[1]));
        moonGradient.addColorStop(1, p.color(selectedMoonColors[2]));

        p.strokeWeight(radius / 5);
        p.stroke(bgColor);
        p.noStroke();

        // Apply the gradient fill
        p.fill(bgColor);
        p.drawingContext.fillStyle = moonGradient;


        p.push();
        p.beginShape();
        switch (phase) {
          case 'Full Moon':
            for (let a = 0; a < p.TWO_PI; a += 0.1) {
              let x = radius / 2 * p.cos(a);
              let y = radius / 2 * p.sin(a);
              p.vertex(x, y - radius / 2);
            }
            break;
          case 'New Moon':
            // p.noStroke();
            for (let a = 0; a < p.TWO_PI; a += 0.1) {
              let x = radius / 2 * p.cos(a);
              let y = radius / 2 * p.sin(a);
              p.vertex(x, y - radius / 2);
            }
            break;
          case 'Waxing Crescent':
          case 'Waning Crescent':
            let xOffset = radius * 0.25;
            let arcSpan = p.PI * 0.825;
            let extendAngle = p.atan2(radius, xOffset) * 0.18;
            drawCrescent(xOffset, arcSpan, extendAngle);
            break;
          case 'First Quarter':
          case 'Last Quarter':
            drawQuarter(true);  // Always draw as if it's First Quarter, scaling will mirror it
            break;
          case 'Waxing Gibbous':
            drawGibbous(true);
            break;
          case 'Waning Gibbous':
            drawGibbous(false);  // Always draw as if it's Waxing Gibbous, scaling will mirror it
            break;
        }
        p.endShape();

        // Draw Glow effect on border
        p.noFill();
        p.strokeWeight(radius / 10);
        p.drawingContext.shadowBlur = padding;
        p.drawingContext.shadowColor = 'rgba(255, 255, 255, 1.0)'

        p.beginShape();
        switch (phase) {
          case 'Full Moon':
            for (let a = 0; a < p.TWO_PI; a += 0.1) {
              let x = radius / 2 * p.cos(a);
              let y = radius / 2 * p.sin(a);
              p.vertex(x, y - radius / 2);
            }
            break;
          case 'Waxing Crescent':
          case 'Waning Crescent':
            let xOffset = radius * 0.25;
            let arcSpan = p.PI * 0.825;
            let extendAngle = p.atan2(radius, xOffset) * 0.18;
            p.scale(phase === 'Waning Crescent' ? -1 : 1, 1);
            drawCrescent(xOffset, arcSpan, extendAngle);
            break;
          case 'First Quarter':
          case 'Last Quarter':
            p.scale(phase === 'Last Quarter' ? -1 : 1, 1);
            drawQuarter(true);  // Always draw as if it's First Quarter, scaling will mirror it
            break;
          case 'Waxing Gibbous':
            drawGibbous(true);
            break;
          case 'Waning Gibbous':
            p.scale(phase === 'Waning Gibbous' ? -1 : 1, 1);
            drawGibbous(false);  // Always draw as if it's Waxing Gibbous, scaling will mirror it
            break;
        }
        p.endShape(p.CLOSE);
        p.pop()

        // Center Glow effect 
        p.push();
        p.noStroke();
        p.fill(bgColor);
        const glowGradient = createRadialGradient(p, 0, -radius / 2, 0, radius / 2);
        p.drawingContext.fillStyle = glowGradient;
        // p.ellipse(0, -radius / 2, radius, radius);

        p.beginShape();

        switch (phase) {
          case 'New Moon':
          case 'Full Moon':
            for (let a = 0; a < p.TWO_PI; a += 0.1) {
              let x = radius / 2 * p.cos(a);
              let y = radius / 2 * p.sin(a);
              p.vertex(x, y - radius / 2);
            }
            break;
          case 'Waxing Crescent':
          case 'Waning Crescent':
            let xOffset = radius * 0.25;
            let arcSpan = p.PI * 0.825;
            let extendAngle = p.atan2(radius, xOffset) * 0.18;
            drawCrescent(xOffset, arcSpan, extendAngle);
            break;
          case 'First Quarter':
          case 'Last Quarter':
            drawQuarter(true);
            break;
          case 'Waxing Gibbous':
            drawGibbous(true);
            break;
          case 'Waning Gibbous':
            // p.scale(phase === 'Waning Gibbous' ? -1 : 1, 1);
            drawGibbous(false);
            break;
        }
        p.endShape(p.CLOSE);

        // Add a blur effect
        // p.filter(p.BLUR, radius / 20);

        p.pop();
        p.pop();




        function createRadialGradient(p, x, y, r1, r2) {
          const gradient = p.drawingContext.createRadialGradient(x, y, r1, x, y, r2);
          gradient.addColorStop(0, 'rgba(255, 255, 255, 0.7)');
          gradient.addColorStop(1, 'rgba(255, 255, 255, 0.15)');
          return gradient;
        };

        function drawCrescent(xOffset, arcSpan, extendAngle) {
          let angleOffset = p.random(p.PI / 8, p.PI / 4);
          for (let a = p.PI / 2 - extendAngle; a <= 3 * p.PI / 2 + extendAngle; a += 0.1) {
            let x = radius / 2 * p.cos(a);
            let y = radius / 2 * p.sin(a);
            p.vertex(x, y - radius / 2);
          }
          for (let a = 3 * p.PI / 2 - (p.PI - arcSpan) / 2; a >= p.PI / 2 + (p.PI - arcSpan) / 2; a -= 0.1) {
            let x = xOffset + radius / 2 * p.cos(a);
            let y = radius / 2 * p.sin(a);
            p.vertex(x, y - radius / 2);
          }

        }

        function drawQuarter(isFirstQuarter) {
          for (let a = p.PI / 2; a <= 3 * p.PI / 2; a += 0.1) {
            let x = radius / 2 * p.cos(a);
            let y = radius / 2 * p.sin(a);
            p.vertex(x, y - radius / 2);
          }
          if (isFirstQuarter) {
            p.vertex(0, -radius);
            p.vertex(0, 0);
          } else {
            p.vertex(0, 0);
            p.vertex(0, -radius);
          }
        }

        function drawGibbous(isWaxing) {
          for (let a = p.PI / 2; a < 3 * p.PI / 2; a += 0.1) {
            let x = radius / 2 * p.cos(a);

            let y = radius / 2 * p.sin(a);
            p.vertex(x, y - radius / 2);
          }
          for (let a = -p.PI / 2; a < p.PI / 2; a += 0.1) {
            let x = radius / 3 * p.cos(a);

            let y = radius / 2 * p.sin(a);
            p.vertex(x, y - radius / 2);
          }
        }

        // Come back to this
        // p.ellipse(0, -radius / 2, radius, radius);
        // Crescent
        // let xOffset = radius * 0.25; // Adjust this value to change crescent thickness
        // let arcSpan = p.PI * 0.825; // Adjust this value to change how much of the circle is drawn

        // let extendAngle = p.atan2(radius, xOffset) * 0.18;

        // p.arc(0, -radius / 2, radius, radius, p.PI / 2 - extendAngle, 3 * p.PI / 2 + extendAngle);
        // p.arc(xOffset, -radius / 2, radius, radius,
        //   p.PI / 2 + (p.PI - arcSpan) / 2,
        //   3 * p.PI / 2 - (p.PI - arcSpan) / 2);

        // Gibbous
        // p.arc(0, -radius / 2, radius, radius, p.PI / 2, 3 * p.PI / 2);
        // p.arc(0, -radius / 2, radius / 1.5, radius, -p.PI / 2, p.PI / 2);
        // p.pop();

        // Quarter
        // p.arc(0, -radius / 2, radius, radius, p.PI / 2, 3 * p.PI / 2);
        // p.line(0, -radius, 0, 0);


      };

      //   }

      //   // Draw the glow effect as a circle with a semi-transparent white edge
      //   p.stroke(bgColor); // White glow effect with transparency
      //   p.strokeWeight(8);
      //   p.noFill();
      //   p.ellipse(centerX, centerY, diameter * 0.8, diameter * 0.8);

      //   p.pop();
      // };



    }
    new p5(sketch, sketchRef.current);

    return () => {
      if (sketchRef.current) {
        sketchRef.current.remove();
      }
    };
  }, [responses, selectedMoon, generatedText]);

  const handleShare = () => {
    if (p5Instance) {
      const canvas = p5Instance.canvas;
      canvas.toBlob((blob) => {
        shareImage(blob).catch((error) => {
          console.error('Error sharing image:', error);
        });
      }, 'image/png', 1.0); // Specify PNG format and full quality
    }
  };

  const moonPhases = [
    {
      name: 'New Moon',
      description: 'You are open to new beginnings.',
      playlist: 'https://open.spotify.com/album/3GpPjgqd5cA9rqiIwofy0V',
      appleMusicPlaylist: 'https://music.apple.com/us/album/new-moon-i-am-open-to-new-beginnings/1769377273',
      colors: ['#7EA4FF', '#A3C8FA', '#F7AB8A']
    },
    {
      name: 'Waxing Crescent',
      description: 'You trust your path and take steps towards your dreams with confidence.',
      playlist: 'https://open.spotify.com/album/3k4LWnqwyTIgJkkeYLpKUJ',
      appleMusicPlaylist: 'https://music.apple.com/us/album/waxing-crescent-i-trust-every-step-that-i-take/1769380358',
      colors: ['#F8D808', '#E66D7B', '#282962']
    },
    {
      name: 'First Quarter',
      description: 'You trust yourself and your decisions.',
      playlist: 'https://open.spotify.com/album/0AgJyeK1zMKPQ79rkut4MK',
      appleMusicPlaylist: 'https://music.apple.com/gb/album/first-quarter-i-trust-myself-and-my-decisions/1769395222',
      colors: ['#FDA2DA', '#7C47DB', '#0A26AC']
    },
    {
      name: 'Waxing Gibbous',
      description: 'You are constantly improving, evolving, and moving closer to your true self.',
      playlist: 'https://open.spotify.com/album/6SZby6nNTIzln8uuvtHaQG',
      appleMusicPlaylist: 'https://music.apple.com/us/album/waxing-gibbous-i-am-constantly-moving-closer-to-my-true-self/1769372017',
      colors: ['#00B7FF', '#FEF439', '#FF3030']
    },
    {
      name: 'Full Moon',
      description: 'Celebrate your progress and honor your journey.',
      playlist: 'https://open.spotify.com/album/4CV5iMxzIfbRVpcQJ8BTsf',
      appleMusicPlaylist: 'https://music.apple.com/us/album/full-moon-i-celebrate-my-progress-and-honor-my-journey/1769382671',
      colors: ['#FC4084', '#FDBFD6', '#395D93']
    },
    {
      name: 'Waning Gibbous',
      description: 'Let go of what no longer serves you with grace and gratitude.',
      playlist: 'https://open.spotify.com/album/21gJKblpk6w44s2aq7Jcjs',
      appleMusicPlaylist: 'https://music.apple.com/us/album/waning-gibbous-i-remain-thankful-as-i-let-go-of-the-past/1769386081',
      colors: ['#FFDBD2', '#DC8169', '#134787']
    },
    {
      name: 'Last Quarter',
      description: 'Release with love and prepare for new beginnings.',
      playlist: 'https://open.spotify.com/album/4FwAxRSOCjOFFaI6DgneAp',
      appleMusicPlaylist: 'https://music.apple.com/us/album/last-quarter-i-forgive-release-and-prepare-for-new/1769385857',
      colors: ['#30B1C9', '#FDDEE1', '#F9C6C8']
    },
    {
      name: 'Waning Crescent',
      description: 'Surrender to the flow of life and embrace the restorative power of rest.',
      playlist: 'https://open.spotify.com/album/2Se78XEWp1RUOcQB3hltws',
      appleMusicPlaylist: 'https://music.apple.com/gb/album/waning-crescent-i-embrace-the-power-of-rest/1769356764',
      colors: ['#F7C6FA', '#96B9EF', '#AFF4CB']
    }
  ];

  const [isOpen, setOpen] = useState(false);

  const handlePlaylistClick = () => {
    setOpen(true);
  };

  const closeSheet = () => {
    setOpen(false);
  };

  const selectedPhase = moonPhases.find(phase => phase.name === selectedMoon);


  return (
    <div className="flex flex-col items-center">
      <div className='border-2 border-solid border-[#0a0a0a] shadow-2xl' ref={sketchRef}></div>
      <div className="w-full max-w-[512px] mt-4 space-y-2">
        <button
          onClick={handleShare}
          className="w-full bg-[#0a0a0a] text-white py-3 rounded-md font-semibold text-lg"
        >
          Share Your Moon Phase
        </button>
        <button
          onClick={handlePlaylistClick}
          className="w-full py-3 px-6 text-white rounded-md text-lg font-semibold cursor-pointer shadow-lg transition-all duration-300 ease-in-out"
          style={{
            background: `linear-gradient(45deg, ${selectedPhase.colors[0]}, ${selectedPhase.colors[1]}, ${selectedPhase.colors[2]})`,
            backgroundSize: '200% 200%',
            animation: 'gradient 5s ease infinite',
            textShadow: '1px 1px 2px rgba(0,0,0,0.3)'
          }}
          onMouseEnter={(e) => e.target.style.backgroundPosition = '100% 100%'}
          onMouseLeave={(e) => e.target.style.backgroundPosition = '0% 0%'}
        >
          Listen to the {selectedMoon} Playlist
        </button>
        <button
          onClick={resetQuestions}
          className="w-full border-2 border-[#0a0a0a] text-[#0a0a0a] py-3 rounded-md font-semibold text-lg hover:bg-[#0a0a0a] hover:text-white transition-colors duration-300"
        >
          Get Another Reading
        </button>
      </div>

      <Sheet
        isOpen={isOpen}
        onClose={closeSheet}
        snapPoints={[0.45, 0.9]}
        initialSnap={0}
      >
        <Sheet.Container>
          <Sheet.Header>
            <div className="w-12 h-1 bg-gray-300 rounded-full mx-auto mt-2 mb-4" />
          </Sheet.Header>
          <Sheet.Content>
            <div className="p-4 flex flex-col font-bodoni font-bold">
              {selectedPhase && (
                <>
                  <h2 className="text-2xl mb-4">Choose your music platform</h2>
                  <div className="flex flex-col space-y-4">
                    <a
                      href={selectedPhase.playlist}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex items-center justify-center px-6 py-4 rounded-md text-white font-semibold text-center transition-all duration-300 ease-in-out hover:scale-105"
                      style={{
                        background: `linear-gradient(45deg, #1DB954, #1ed760, #2ebd59)`,
                        backgroundSize: '200% 200%',
                        animation: 'gradient 50s ease infinite',
                        textShadow: '1px 1px 2px rgba(0,0,0,0.3)'
                      }}
                    >
                      Listen on Spotify
                    </a>
                    <a
                      href={selectedPhase.appleMusicPlaylist}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex items-center justify-center px-6 py-4 rounded-md text-white font-semibold text-center transition-all duration-300 ease-in-out hover:scale-105"
                      style={{
                        background: `linear-gradient(45deg, #FA2D48, #fb4d64, #fc6d80)`,
                        backgroundSize: '200% 200%',
                        animation: 'gradient 50s ease infinite',
                        textShadow: '1px 1px 2px rgba(0,0,0,0.3)'
                      }}
                    >
                      Listen on Apple Music
                    </a>
                  </div>
                </>
              )}
            </div>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop onTap={closeSheet} />
      </Sheet>

      <style jsx>{`
        @keyframes gradient {
          0% { background-position: 0% 50%; }
          50% { background-position: 100% 50%; }
          100% { background-position: 0% 50%; }
        }
      `}</style>
    </div>
  );
};



export default ResultsSlide;

