import React from 'react';
import { Link, useLocation } from 'react-router-dom';

function NavCard() {
    const moonPhases = [
        ['#7EA4FF', '#A3C8FA', '#F7AB8A'],
        ['#F8D808', '#E66D7B', '#282962'],
        ['#FDA2DA', '#7C47DB', '#0A26AC'],
        ['#00B7FF', '#FEF439', '#FF3030'],
        ['#FC4084', '#FDBFD6', '#395D93'],
        ['#FFDBD2', '#DC8169', '#134787'],
        ['#30B1C9', '#FDDEE1', '#F9C6C8'],
        ['#F7C6FA', '#96B9EF', '#AFF4CB']
    ];

    const location = useLocation();

    return (
        <nav className="flex justify-between items-center mb-12">
            <Link to="/">
                <div className="block">
                    <h1
                        className="text-lg md:text-3xl font-bold"
                        style={{
                            background: `linear-gradient(45deg, ${moonPhases.flat().join(', ')})`,
                            backgroundSize: '1000% 1000%',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent',
                            animation: 'gradient 50s ease infinite'
                        }}
                    >
                        The Moon Teller
                    </h1>
                </div>
            </Link>
            <div>
                {location.pathname !== '/questions' && (
                <Link 
                    to="/questions" 
                    className="ml-10 text-lg md:text-2xl relative group"
                >
                    <span className="relative z-10 transition-colors duration-300 group-hover:text-transparent">Create</span>
                    <span 
                        className="absolute inset-0 opacity-0 group-hover:opacity-100 transition-opacity duration-300"
                        style={{
                            background: `linear-gradient(45deg, ${moonPhases[6].join(', ')})`,
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent',
                        }}
                    >
                        Create
                    </span>
                </Link>
                )}
                <Link 
                    to="/about" 
                    className="ml-10 text-lg md:text-2xl relative group"
                >
                    <span className="relative z-10 transition-colors duration-300 group-hover:text-transparent">About</span>
                    <span 
                        className="absolute inset-0 opacity-0 group-hover:opacity-100 transition-opacity duration-300"
                        style={{
                            background: `linear-gradient(45deg, ${moonPhases[1].join(', ')})`,
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent',
                        }}
                    >
                        About
                    </span>
                </Link>
            </div>
            <style jsx>{`
                @keyframes gradient {
                    0% { background-position: 0% 50%; }
                    50% { background-position: 100% 50%; }
                    100% { background-position: 0% 50%; }
                }
            `}</style>
        </nav>
    );
}

export default NavCard;