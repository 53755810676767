import React from 'react';

function SummarySlide({ responses }) {
    return (
        <div className="w-full max-w-2xl mx-auto">
            <h2 className="text-3xl font-bold mb-6 text-center">Summary of Responses</h2>
            <div className="bg-white bg-opacity-50 rounded-lg shadow-lg p-6">
                {responses.map((response, index) => (
                    <div key={index} className="mb-4 last:mb-0">
                        <h3 className="text-lg font-semibold mb-1">{response.question}</h3>
                        <p className="text-xl bg-white bg-opacity-70 rounded-md p-3 shadow-sm">
                            {Array.isArray(response.answer) ? response.answer.join(', ') : response.answer}
                        </p>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default SummarySlide;