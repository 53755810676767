const dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl.split(","),
      mimeType = arr[0].match(/:(.*?);/)[1],
      decodedData = atob(arr[1]),
      lengthOfDecodedData = decodedData.length,
      u8array = new Uint8Array(lengthOfDecodedData);
    while (lengthOfDecodedData--) {
      u8array[lengthOfDecodedData] = decodedData.charCodeAt(lengthOfDecodedData);
    }
    return new File([u8array], filename, { type: mimeType });
  };
  
  const shareFile = (file, title, text) => {
    if (navigator.canShare && navigator.canShare({ files: [file] })) {
      return navigator
        .share({
          files: [file],
          title: title,
          text: text
        })
        .then(() => console.log("Share was successful."))
        .catch((error) => {
          console.log("Sharing cancelled or failed:", error);
        });
    } else {
      console.log(`Your system doesn't support sharing files.`);
      return Promise.reject(new Error("File sharing not supported"));
    }
  };
  
  export const shareImage = async (imageBlob) => {
    if (navigator.canShare) {
      const reader = new FileReader();
      reader.readAsDataURL(imageBlob);
      
      reader.onloadend = function() {
        const dataUrl = reader.result;
        const file = dataURLtoFile(dataUrl, 'moonteller-result.png');
        shareFile(file, 'My MoonTeller Result', 'Check out my MoonTeller prophecy!');
      };
    } else {
      fallbackShare(imageBlob);
    }
  };
  
  const fallbackShare = async (imageBlob) => {
    const imageUrl = URL.createObjectURL(imageBlob);
    const a = document.createElement('a');
    a.href = imageUrl;
    a.download = 'moonteller-result.png';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(imageUrl);
  
    try {
      const clipboardItem = new ClipboardItem({
        'image/png': imageBlob
      });
      await navigator.clipboard.write([clipboardItem]);
      alert('Image downloaded and copied to clipboard! You can paste it to share.');
    } catch (err) {
      console.error('Failed to copy image to clipboard:', err);
      alert('Image downloaded. Unable to copy to clipboard.');
    }
  };