import { db } from "../firebase";
import { collection, addDoc } from "firebase/firestore";

const saveResponses = async (responses) => {
    try {
        // Get the connected service from localStorage
        const connectedService = localStorage.getItem('connectedService') || 'email';

        const docRef = await addDoc(collection(db, "responses"), {
            responses,
            timestamp: new Date(),
            connectedService: connectedService,
        });
        console.log("Response submitted successfully!");
    } catch (e) {
        console.error("Error adding document: ", e);
    }
};

export default saveResponses;