import React, { useState, useEffect } from 'react';
import NavCard from '../components/NavCard';
import Slide from '../components/Slide';
import SummarySlide from '../components/SummarySlide';
import ResultsSlide from '../components/ResultsSlide';
import Footer from '../components/Footer';
import generateText from '../utils/textGeneration';
import saveResponses from '../utils/saveResponses';


const TEST_CASE = {
    responses: [
        { question: "What's your name?", answer: "John" },
        { question: "What's your Zodiac sign?", answer: "Libra" },
        { question: "What's your favorite album? Your favorite lyric?", answer: "Test Album - Test Lyric" },
        { question: "What kind of playlists do you usually listen to?", answer: ["Relaxing sounds, like rain sounds", "Chill acoustic"] }
    ],
    selectedMoon: "Full Moon",
    generatedText: "This is a test 안녕하세요 for the Waning Gibbous phase. The stars align for Test User, an Aries born in the 1990s. Your favorite album speaks volumes about your journey. As you listen to relaxing sounds and chill acoustic music, remember that your path is illuminated by the moon's gentle glow.",
    extractedName: "안녕하세요"
};

const questions = [
    {
        type: 'open',
        question: "What's your name?",
        placeholder: 'Answer here...',
    },
    {
        type: 'multiple',
        question: "What's your Zodiac sign?",
        options: [
            'Aries',
            'Taurus',
            'Gemini',
            'Cancer',
            'Leo',
            'Virgo',
            'Libra',
            'Scorpio',
            'Sagittarius',
            'Capricorn',
            'Aquarius',
            'Pisces',
        ],
        multiSelect: false,
    },
    {
        type: 'open',
        question: "What's one of your favorite albums right now?",
        placeholder: 'Answer here...',
    },
    {
        type: 'multiple',
        question: 'Among these options, which playlist do you think you would be interested in the most?',
        options: [
            'Relaxing sounds, like rain sounds',
            'Uptempo, electronic music',
            'Soulful music',
            'Chill acoustic',
            'EDM Workout vibe',
            'Classical orchestral',
            'Jazz and blues',
            'Hip-hop and rap',
            'Rock and alternative',
            'Country and folk',
            'Ambient and atmospheric',
            'Indie and underground',
        ],
        multiSelect: false,
    },
    {
        type: 'multiple',
        question: 'Whenever you see the moon, how do you feel?',
        options: [
            'I feel happy',
            'I feel sad',
            'I feel excited',
            'I feel calm',
            'I feel nostalgic',
            'I feel anxious',
            'I feel nothing',
        ],
        multiSelect: false,
    },
    {
        type: 'multiple',
        question: 'What is your biggest priority in life right now?',
        options: [
            'Career',
            'Health',
            'Relationships',
            'Finances',
            'Personal Growth and Wellness',
            'Something else',
        ],
        multiSelect: false,
    },
    {
        type: 'multiple',
        question: 'Today feels like...',
        options: [
            '...a day when I am at my best.',
            '...a day to try out something new.',
            '...a gloomy day.',
            '...a weird and ominous day.',
            '...any other normal day.',
        ],
        multiSelect: false,
    },
];

function Questions() {
    const [currentSlide, setCurrentSlide] = useState(0);
    const [responses, setResponses] = useState(Array(questions.length).fill(''));
    const [showSummary, setShowSummary] = useState(false);

    // TODO: Add these back in
    const [submittedResponses, setSubmittedResponses] = useState([]);
    const [showResults, setShowResults] = useState(false);
    const [generatedText, setGeneratedText] = useState('');
    const [extractedName, setExtractedName] = useState('');
    const [selectedMoon, setSelectedMoon] = useState('');

    const resetQuestions = () => {
        setCurrentSlide(0);
        setResponses(Array(questions.length).fill(''));
        setShowSummary(false);
        setShowResults(false);
        setSubmittedResponses([]);
        setGeneratedText('');
        setExtractedName('');
        setSelectedMoon('');
    };

    // Testing variables
    // const [showResults, setShowResults] = useState(true); // Default to showing results
    // const [generatedText, setGeneratedText] = useState(TEST_CASE.generatedText);
    // const [selectedMoon, setSelectedMoon] = useState(TEST_CASE.selectedMoon);
    // const [submittedResponses, setSubmittedResponses] = useState(TEST_CASE.responses);
    // const [extractedName, setExtractedName] = useState(TEST_CASE.extractedName);

    const moonPhases = [
        ['#7EA4FF', '#A3C8FA', '#F7AB8A'],
        ['#F8D808', '#E66D7B', '#282962'],
        ['#FDA2DA', '#7C47DB', '#0A26AC'],
        ['#00B7FF', '#FEF439', '#FF3030'],
        ['#FC4084', '#FDBFD6', '#395D93'],
        ['#FFDBD2', '#DC8169', '#134787'],
        ['#30B1C9', '#FDDEE1', '#F9C6C8'],
        ['#F7C6FA', '#96B9EF', '#AFF4CB']
    ];

    const [prevGradient, setPrevGradient] = useState('');
    const [nextGradient, setNextGradient] = useState('');

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        randomizeGradients();
    }, [currentSlide]);

    const randomizeGradients = () => {
        const randomIndex1 = Math.floor(Math.random() * moonPhases.length);
        let randomIndex2 = Math.floor(Math.random() * moonPhases.length);
        while (randomIndex2 === randomIndex1) {
            randomIndex2 = Math.floor(Math.random() * moonPhases.length);
        }
        setPrevGradient(`linear-gradient(45deg, ${moonPhases[randomIndex1].join(', ')})`);
        setNextGradient(`linear-gradient(45deg, ${moonPhases[randomIndex2].join(', ')})`);
    };

    const handleNext = () => {
        if (currentSlide < questions.length - 1) {
            setCurrentSlide(currentSlide + 1);
        } else {
            setShowSummary(true);
        }
    };

    const handlePrev = () => {
        if (showResults) {
            setShowResults(false);
            setShowSummary(true);
        } else if (showSummary) {
            setShowSummary(false);
            setCurrentSlide(questions.length - 1);
        } else if (currentSlide > 0) {
            setCurrentSlide(currentSlide - 1);
        }
    };

    const handleResponseChange = (response) => {
        const newResponses = [...responses];
        newResponses[currentSlide] = response;
        setResponses(newResponses);
    };

    const determineMoonPhase = (text) => {
        const moonPhases = [
            "Waning Gibbous", "New Moon", "First Quarter", "Last Quarter",
            "Waxing Gibbous", "Waning Crescent", "Waxing Crescent", "Full Moon"
        ];

        for (const phase of moonPhases) {
            if (text.includes(phase)) {
                return phase;
            }
        }

        return "Lunar Phase";
    };

    const isResponseValid = (response, questionType) => {
        if (!response) return false;
        
        if (questionType === 'open') {
            return response.trim().length > 0;
        } else if (questionType === 'multiple') {
            return response !== '';
        }
        
        return false;
    };

    const handleSubmit = async () => {
        if (!showSummary) {
            const currentQuestion = questions[currentSlide];
            if (!isResponseValid(responses[currentSlide], currentQuestion.type)) {
                alert('Please provide an answer before continuing.');
                return;
            }

            const newSubmittedResponses = [...submittedResponses];
            newSubmittedResponses[currentSlide] = {
                question: questions[currentSlide].question,
                answer: responses[currentSlide],
            };
            setSubmittedResponses(newSubmittedResponses);
            handleNext();
        } else {
            setIsLoading(true);
            try {
                await saveResponses(submittedResponses);
                const { generatedText, extractedName } = await generateText(submittedResponses);
                setGeneratedText(generatedText);
                setExtractedName(extractedName);
                const moonPhase = determineMoonPhase(generatedText);
                setSelectedMoon(moonPhase);
                setShowResults(true);
            } catch (error) {
                console.error("Error in handleSubmit:", error);
            } finally {
                setIsLoading(false);
            }
        }
    };

    const handleTestSubmit = () => {
        setSubmittedResponses(TEST_CASE.responses);
        setSelectedMoon(TEST_CASE.selectedMoon);
        setGeneratedText(TEST_CASE.generatedText);
        setShowResults(true);
    };

    return (
        <div className="font-bodoni max-w-full min-h-screen flex flex-col p-10">
            <NavCard />
            <main className={`flex-grow flex flex-col items-center text-center text-[#0a0a0a] ${isLoading ? 'justify-center' : ''}`}>
                {isLoading ? (
                    <div className="flex items-center justify-center h-full">
                        <img src="./Assets/Images/MoonPhases.gif" alt="Loading" className="w-1/2 max-w-md" />
                    </div>
                ) : (
                    <>
                        {showResults ? (
                            <ResultsSlide
                                responses={submittedResponses}
                                selectedMoon={selectedMoon}
                                generatedText={generatedText}
                                extractedName={extractedName}
                                resetQuestions={resetQuestions}
                            />
                        ) : showSummary ? (
                            <SummarySlide responses={submittedResponses} />
                        ) : (
                            <Slide
                                question={{ ...questions[currentSlide], index: currentSlide, total: questions.length }}
                                response={responses[currentSlide]}
                                onResponseChange={handleResponseChange}
                            />
                        )}
                        <div className="flex justify-between w-full md:w-3/5 mt-6">
                            {!showResults && (
                                <button
                                    className="py-2 px-4 w-1/3 md:w-1/4 text-white bg-[#0a0a0a] rounded-md text-md md:text-xl cursor-pointer hover:scale-105 transition-all duration-300 relative group overflow-hidden"
                                    onClick={handlePrev}
                                    disabled={currentSlide === 0 && !showSummary}
                                >
                                    <span className="relative z-10">Previous</span>
                                </button>
                            )}
                            {!showResults && (
                                <button
                                    className={`py-2 px-4 w-1/3 md:w-1/4 text-white rounded-md text-md md:text-xl transition-all duration-300 relative group overflow-hidden ${
                                        isResponseValid(responses[currentSlide], questions[currentSlide].type)
                                        ? 'cursor-pointer hover:scale-105'
                                        : 'cursor-not-allowed opacity-50'
                                    }`}
                                    onClick={handleSubmit}
                                    style={{
                                        background: nextGradient,
                                        textShadow: '1px 1px 2px rgba(0,0,0,0.3)'
                                    }}
                                    disabled={!isResponseValid(responses[currentSlide], questions[currentSlide].type) || isLoading}
                                >
                                    <span className="relative z-10">{showSummary ? 'Generate' : 'Next'}</span>
                                </button>
                            )}
                        </div>
                    </>
                )}
            </main>
            <Footer />
        </div>
    );
}

export default Questions;