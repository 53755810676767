import React, { useMemo } from 'react';

const generateRandomShape = (size) => {
    const points = 12; // Increased for more irregularity
    const center = size / 2;
    let path = `M ${center},${center} `;
  
    for (let i = 0; i < points; i++) {
      const angle = (i / points) * 2 * Math.PI;
      const radius = center * (0.3 + Math.random() * 0.7); // More variation
      const x = center + radius * Math.cos(angle);
      const y = center + radius * Math.sin(angle);
      path += `Q ${center + Math.random() * 50 - 25},${center + Math.random() * 50 - 25} ${x},${y} `; // Using quadratic curves for smoother shape
    }
  
    path += 'Z';
    return path;
}

// Moon Phase Colors for reference
const colorPairs = [
  ['#FDA2DA88', '#7C47DB88', '#0A26AC88'], // First Quarter
  ['#F8D80888', '#E66D7B88', '#28296288'], // Waxing Crescent
  ['#00B7FF88', '#FEF43988', '#FF303088'], // Waxing Gibbous
  ['#FFDBD288', '#DC816988', '#13478788'], // Waning Gibbous
  ['#F7C6FA88', '#96B9EF88', '#AFF4CB88'], // Waning Crescent
  ['#7EA4FF88', '#A3C8FA88', '#F7AB8A88'], // New Moon
  ['#FC408488', '#FDBFD688', '#395D9388'], // Full Moon
  ['#30B1C988', '#FDDEE188', '#F9C6C888'],

   // Added transparency
];

const BlurredGradient = ({ className = '' }) => {
  const size = '70vw'; // Increased size
  const shape = useMemo(() => generateRandomShape(600), []); // Increased base size
  const [color1, color2, color3] = useMemo(() => colorPairs[Math.floor(Math.random() * colorPairs.length)], []);

  const randomPosition = useMemo(() => ({
    top: `${40 + Math.random() * 20}%`,
    left: `${40 + Math.random() * 20}%`,
  }), []);

  return (
    <div className={`absolute ${className}`}
         style={{ 
           width: size, 
           height: size, 
           zIndex: 1, 
           ...randomPosition,
           transform: 'translate(-50%, -50%)'
         }}>
      <svg width="100%" height="100%" viewBox="0 0 600 600" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_f_1_2)">
          <path d={shape} fill="url(#paint0_radial_1_2)"/>
        </g>
        <defs>
          <filter id="filter0_f_1_2" x="-200" y="-200" width="1000" height="1000" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
            <feGaussianBlur stdDeviation="100" result="effect1_foregroundBlur_1_2"/> // Increased blur
          </filter>
          <radialGradient id="paint0_radial_1_2" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(300 300) rotate(90) scale(300)">
            <stop stopColor={color1}/>
            <stop offset="0.5" stopColor={color2}/>
            <stop offset="1" stopColor={color3}/>
          </radialGradient>
        </defs>
      </svg>
    </div>
  );
};

export default BlurredGradient;