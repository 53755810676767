// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

// Need to move these to a .env file
const firebaseConfig = {
  apiKey: "AIzaSyCB4sWnV_DNeDABry-8Xvv0VMfQCDtELME",
  authDomain: "warner-music-moon-phases.firebaseapp.com",
  projectId: "warner-music-moon-phases",
  storageBucket: "warner-music-moon-phases.appspot.com",
  messagingSenderId: "263901192877",
  appId: "1:263901192877:web:4bc4b5b93d72ca00535c24",
  measurementId: "G-B21WPY6QQS"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const db = getFirestore(app);

export { db, analytics, app, getFirestore, getAnalytics, initializeApp };