import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import NavCard from '../components/NavCard';
import Footer from '../components/Footer';
import { Sheet } from 'react-modal-sheet';

function About() {
    const [isOpen, setOpen] = useState(false);
    const [selectedPhase, setSelectedPhase] = useState(null);
    const [contentHeight, setContentHeight] = useState(0);
    const contentRef = useRef(null);

    const moonPhases = [
        {
            name: 'New Moon',
            description: 'You are open to new beginnings.',
            playlist: 'https://open.spotify.com/album/3GpPjgqd5cA9rqiIwofy0V',
            appleMusicPlaylist: 'https://music.apple.com/us/album/new-moon-i-am-open-to-new-beginnings/1769377273',
            colors: ['#7EA4FF', '#A3C8FA', '#F7AB8A']
        },
        {
            name: 'Waxing Crescent',
            description: 'You trust your path and take steps towards your dreams with confidence.',
            playlist: 'https://open.spotify.com/album/3k4LWnqwyTIgJkkeYLpKUJ',
            appleMusicPlaylist: 'https://music.apple.com/us/album/waxing-crescent-i-trust-every-step-that-i-take/1769380358',
            colors: ['#F8D808', '#E66D7B', '#282962']
        },
        {
            name: 'First Quarter',
            description: 'You trust yourself and your decisions.',
            playlist: 'https://open.spotify.com/album/0AgJyeK1zMKPQ79rkut4MK',
            appleMusicPlaylist: 'https://music.apple.com/gb/album/first-quarter-i-trust-myself-and-my-decisions/1769395222',
            colors: ['#FDA2DA', '#7C47DB', '#0A26AC']
        },
        {
            name: 'Waxing Gibbous',
            description: 'You are constantly improving, evolving, and moving closer to your true self.',
            playlist: 'https://open.spotify.com/album/6SZby6nNTIzln8uuvtHaQG',
            appleMusicPlaylist: 'https://music.apple.com/us/album/waxing-gibbous-i-am-constantly-moving-closer-to-my-true-self/1769372017',
            colors: ['#00B7FF', '#FEF439', '#FF3030']
        },
        {
            name: 'Full Moon',
            description: 'Celebrate your progress and honor your journey.',
            playlist: 'https://open.spotify.com/album/4CV5iMxzIfbRVpcQJ8BTsf',
            appleMusicPlaylist: 'https://music.apple.com/us/album/full-moon-i-celebrate-my-progress-and-honor-my-journey/1769382671',
            colors: ['#FC4084', '#FDBFD6', '#395D93']
        },
        {
            name: 'Waning Gibbous',
            description: 'Let go of what no longer serves you with grace and gratitude.',
            playlist: 'https://open.spotify.com/album/21gJKblpk6w44s2aq7Jcjs',
            appleMusicPlaylist: 'https://music.apple.com/us/album/waning-gibbous-i-remain-thankful-as-i-let-go-of-the-past/1769386081',
            colors: ['#FFDBD2', '#DC8169', '#134787']
        },
        {
            name: 'Last Quarter',
            description: 'Release with love and prepare for new beginnings.',
            playlist: 'https://open.spotify.com/album/4FwAxRSOCjOFFaI6DgneAp',
            appleMusicPlaylist: 'https://music.apple.com/us/album/last-quarter-i-forgive-release-and-prepare-for-new/1769385857',
            colors: ['#30B1C9', '#FDDEE1', '#F9C6C8']
        },
        {
            name: 'Waning Crescent',
            description: 'Surrender to the flow of life and embrace the restorative power of rest.',
            playlist: 'https://open.spotify.com/album/2Se78XEWp1RUOcQB3hltws',
            appleMusicPlaylist: 'https://music.apple.com/gb/album/waning-crescent-i-embrace-the-power-of-rest/1769356764',
            colors: ['#F7C6FA', '#96B9EF', '#AFF4CB']
        }
    ];

    const openSheet = (phase) => {
        setSelectedPhase(phase);
        setOpen(true);
    };

    const closeSheet = () => {
        setOpen(false);
        setSelectedPhase(null);
    };

    useEffect(() => {
        if (contentRef.current) {
            setContentHeight(contentRef.current.scrollHeight);
        }
    }, [selectedPhase]);

    return (
        <div className="font-bodoni max-w-full min-h-screen flex flex-col p-10">
            <NavCard />
            <main className="flex-grow flex flex-col items-center text-center">
                <h1 className="text-5xl mb-8">About The Moon Teller</h1>
                <p className="text-xl mb-8 max-w-3xl">
                    The Moon Teller is a unique project designed to help you reflect on the past and prepare for the new year. By aligning with the cycles of the moon, you'll receive guiding words and curated music that resonate with your personal journey.
                </p>
                <p className="text-xl mb-8 max-w-3xl">
                    Each moon phase offers a different energy and focus, allowing you to harmonize with nature's rhythms as you set intentions and embrace growth.
                </p>

                <h2 className="text-3xl mb-6">Moon Phases and Their Playlists</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
                    {moonPhases.map((phase, index) => (
                        <div key={index} className="border border-gray-300 rounded-lg p-4">
                            <h3 className="text-2xl mb-2">{phase.name}</h3>
                            <p className="mb-4">{phase.description}</p>
                            <button
                                onClick={() => openSheet(phase)}
                                className="inline-block w-full md:text-lg text-base px-2 md:px-6 py-3 rounded-md text-white font-semibold transition-all duration-300 ease-in-out hover:scale-105"
                                style={{
                                    background: `linear-gradient(45deg, ${phase.colors[0]}, ${phase.colors[1]}, ${phase.colors[2]})`,
                                    backgroundSize: '200% 200%',
                                    animation: 'gradient 50s ease infinite',
                                    textShadow: '1px 1px 2px rgba(0,0,0,0.3)'
                                }}
                                onMouseEnter={(e) => e.target.style.backgroundPosition = '100% 100%'}
                                onMouseLeave={(e) => e.target.style.backgroundPosition = '0% 0%'}
                            >
                                Listen to the {phase.name} Playlist
                            </button>
                        </div>
                    ))}
                </div>

                <Sheet
                    isOpen={isOpen}
                    onClose={closeSheet}
                    snapPoints={[Math.min(contentHeight * 2 / window.innerHeight, 0.9), 1]}
                    initialSnap={0}
                >
                    <Sheet.Container>
                        <Sheet.Header>
                            <div className="w-12 h-1 bg-gray-300 rounded-full mx-auto mt-2 mb-4" />
                        </Sheet.Header>
                        <Sheet.Content>
                            <div ref={contentRef} className="p-4 flex flex-col font-bodoni font-bold">
                                {selectedPhase && (
                                    <>
                                        <h2 className="text-2xl mb-4">Choose your music platform</h2>
                                        <div className="flex flex-col space-y-4">
                                            <a
                                                href={selectedPhase.playlist}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="flex items-center justify-center px-6 py-4 rounded-md text-white font-semibold text-center transition-all duration-300 ease-in-out hover:scale-105"
                                                style={{
                                                    background: `linear-gradient(45deg, #1DB954, #1ed760, #2ebd59)`,
                                                    backgroundSize: '200% 200%',
                                                    animation: 'gradient 50s ease infinite',
                                                    textShadow: '1px 1px 2px rgba(0,0,0,0.3)'
                                                }}
                                            >
                                                Listen on Spotify
                                            </a>
                                            <a
                                                href={selectedPhase.appleMusicPlaylist}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="flex items-center justify-center px-6 py-4 rounded-md text-white font-semibold text-center transition-all duration-300 ease-in-out hover:scale-105"
                                                style={{
                                                    background: `linear-gradient(45deg, #FA2D48, #fb4d64, #fc6d80)`,
                                                    backgroundSize: '200% 200%',
                                                    animation: 'gradient 50s ease infinite',
                                                    textShadow: '1px 1px 2px rgba(0,0,0,0.3)'
                                                }}
                                            >
                                                Listen on Apple Music
                                            </a>
                                        </div>
                                    </>
                                )}
                            </div>
                        </Sheet.Content>
                    </Sheet.Container>
                    <Sheet.Backdrop onTap={closeSheet} />
                </Sheet>
            </main>
            <Footer />
            <style jsx>{`
                @keyframes gradient {
                    0% {
                        background-position: 0% 50%;
                    }
                    50% {
                        background-position: 100% 50%;
                    }
                    100% {
                        background-position: 0% 50%;
                    }
                }
            `}</style>
        </div>
    );
}

export default About;
