import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import NavCard from '../components/NavCard';
import Footer from '../components/Footer';
import SignUpComponent from '../components/EmailSignUp';
import { useWmgConnect } from '../components/WMGConnect';
import useScript from '../components/useScript';




function Landing() {
    const gradientColors = ['#7EA4FF', '#A3C8FA', '#F7AB8A']; // New Moon colors

    const [showModal, setShowModal] = useState(false);
    const [selectedPlatform, setSelectedPlatform] = useState('');
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [showSignUp, setShowSignUp] = useState(false);
    const [isSignedUp, setIsSignedUp] = useState(false);
    const navigate = useNavigate();
    // const { status, api } = useWmgConnect('your-campaign-id');
    useScript('https://wct.live/app/sdk/v2/platform.js?campaign=41604');
    const connectButtonRef = useRef(null);



    useEffect(() => {
        // Check if user is signed up (you might want to use a more robust method)
        const userSignedUp = localStorage.getItem('userSignedUp');
        setIsSignedUp(userSignedUp === 'true');

        const initWMGConnect = () => {
            if (window.WMGConnect && !isSignedUp) {
                connectButtonRef.current = new window.WMGConnect(".wmg-button-submit", {
                    // Enable debug mode for additional console logging
                    debug: true,

                    // Specify a custom data source for ExactTarget (Salesforce Marketing Cloud)
                    exacttarget_datasource: 'WMG_YourArtist_PreSave',

                    opt_in_thank_you_enabled: false,
                    // Enable tracking with Adobe Analytics
                    track_adobe: true,

                    // Customize button text
                    button_collect_email_button_text: 'Submit',
                    button_collect_email_form_back_text: 'Back',

                    // Specify required permissions for Spotify
                    spotify_permissions: ['user-read-email', 'user-read-private', 'user-library-modify'],

                    // Specify required permissions for Apple Music
                    apple_permissions: ['user-read-email', 'user-library-modify'],
                });

                // Set up the callback function
                connectButtonRef.current.setCallback(function (connect_data) {
                    console.log('Connection process completed:', connect_data);
                    const service = connect_data.service;
                    setIsSignedUp(true);
                    localStorage.setItem('userSignedUp', 'true');
                    localStorage.setItem('connectedService', service);
                    setShowSignUp(false);
                    console.log("Navigating to /questions");
                    setTimeout(() => {
                        navigate('/questions', { replace: true });
                    }, 100);
                    // Handle the connection data here
                });

                // Add click event listeners to the buttons
                // document.querySelectorAll('.wmg-button').forEach(button => {
                //     button.addEventListener('click', function (e) {
                //         e.preventDefault();
                //         const platform = this.getAttribute('data-platform');
                //         connectButtonRef.current.startProcess(platform);
                //     });
                // });
            }
            // else {
            //     // If WMGConnect is not available yet, try again in 100ms
            //     setTimeout(initWMGConnect, 100);
            // }

            return () => {
                if (connectButtonRef.current) {
                    // If WMGConnect has a cleanup method, call it here
                }
            };
        };

        initWMGConnect();
    }, []);

    const handleButtonClick = (platform) => {
        console.log("handleButtonClick called", platform);
        setSelectedPlatform(platform);
        setShowModal(true);
        initWMGConnect();
    };

    const initWMGConnect = () => {
        if (window.WMGConnect) {
            connectButtonRef.current = new window.WMGConnect(".wmg-button-submit", {
                // Enable debug mode for additional console logging
                debug: true,

                // Specify a custom data source for ExactTarget (Salesforce Marketing Cloud)
                exacttarget_datasource: 'WMG_YourArtist_PreSave',

                // Enable and customize the opt-in thank you message
                opt_in_thank_you_enabled: false,

                // Enable tracking with Adobe Analytics
                track_adobe: true,
                disable_popup: true,

                // Customize button text
                button_collect_email_button_text: 'Submit',
                button_collect_email_form_back_text: 'Back',

                // Specify required permissions for Spotify
                spotify_permissions: ['user-read-email', 'user-read-private', 'user-library-modify'],

                // Specify required permissions for Apple Music
                apple_permissions: ['user-read-email', 'user-library-modify'],
            });

            // Set up the callback function
            connectButtonRef.current.setCallback(function (connect_data) {
                console.log('Connection process completed:', connect_data);
                setIsSignedUp(true);
                localStorage.setItem('userSignedUp', 'true');
                setShowSignUp(false);
                console.log("Navigating to /questions");
                setTimeout(() => {
                    navigate('/questions', { replace: true });
                }, 100);
                // Handle the connection data here
            });

            return () => {
                if (connectButtonRef.current) {
                    // If WMGConnect has a cleanup method, call it here
                }
            };
        }
    };

    const handleSubmit = () => {
        console.log("handleSubmit called", termsAccepted, connectButtonRef.current);
        if (termsAccepted && connectButtonRef.current) {
            connectButtonRef.current.startProcess(selectedPlatform);
            setShowModal(false);
        }
    };

    const handleDiscoverClick = () => {
        console.log("isSignedUp:", isSignedUp); // Log the value of isSignedUp
        if (isSignedUp) {
            console.log("Navigating to /questions");
            navigate('/questions');
        } else {
            console.log("Showing sign up modal");
            setShowSignUp(true);
        }
    };

    const handleSignUpSuccess = () => {
        setIsSignedUp(true);
        localStorage.setItem('userSignedUp', 'true');
        setShowSignUp(false);
        setTimeout(() => {
            navigate('/questions', { replace: true });
        }, 100);
    };

    // const handleSpotifyConnect = () => {
    //     if (status === 'ready' && api) {
    //         api.startProcess('spotify');
    //         api.setCallback((response) => {
    //             console.log('WMGConnect response:', response);
    //             // Handle the response here. For example:
    //             if (response.service === 'spotify' && response.access_token) {
    //                 console.log('Successfully connected to Spotify');
    //                 // You might want to store the access token or update the UI
    //             }
    //         });
    //     } else {
    //         console.error('WMGConnect is not ready');
    //     }
    // };
    const spotifyGradientColors = ['#1DB954', '#1DB954', '#f5f5f5'];
    const appleGradientColors = ['#FA2D48', '#FA2D48', '#f5f5f5'];

    return (
        <div className="font-bodoni max-w-full min-h-screen flex flex-col p-10">
            <NavCard />
            <main className="flex-grow flex flex-col items-center justify-center text-center">

                <h1 className="text-3xl sm:text-5xl mb-6">Meet the Moon Teller</h1>
                <h2 className="text-xl sm:text-3xl mb-12">Discover Your Moon Phase</h2>

                <div className="w-full max-w-[512px] space-y-4">
                    {!isSignedUp ? (
                        <>
                            <button
                                type="button"
                                className="wmg-button w-full py-3 px-6 bg-[#1DB954] text-white rounded-md text-sm sm:text-lg font-semibold cursor-pointer shadow-md transition-all duration-300 relative group overflow-hidden"
                                onClick={() => handleButtonClick('spotify')}
                            >
                                <span className="relative z-10">Continue With Spotify</span>
                                <span
                                    className="absolute inset-0 opacity-0 group-hover:opacity-100 transition-opacity duration-300"
                                    style={{
                                        background: `linear-gradient(45deg, ${spotifyGradientColors.join(', ')})`,
                                        backgroundSize: '200% 200%',
                                        animation: 'gradient 5s ease infinite',
                                    }}
                                ></span>
                            </button>
                            <button
                                type="button"
                                className="wmg-button w-full py-3 px-6 bg-[#FA2D48] text-white rounded-md text-sm sm:text-lg font-semibold cursor-pointer shadow-md transition-all duration-300 relative group overflow-hidden"
                                onClick={() => handleButtonClick('apple')}
                            >
                                <span className="relative z-10">Continue With Apple Music</span>
                                <span
                                    className="absolute inset-0 opacity-0 group-hover:opacity-100 transition-opacity duration-300"
                                    style={{
                                        background: `linear-gradient(45deg, ${appleGradientColors.join(', ')})`,
                                        backgroundSize: '200% 200%',
                                        animation: 'gradient 5s ease infinite',
                                    }}
                                ></span>
                            </button>
                            <button
                                className="block w-full py-3 px-6 bg-[#0a0a0a] text-white rounded-md text-sm sm:text-lg font-semibold cursor-pointer shadow-md transition-all duration-300 relative group overflow-hidden"
                                onClick={handleDiscoverClick}
                            >
                                <span className="relative z-10">Continue With Email</span>
                                <span
                                    className="absolute inset-0 opacity-0 group-hover:opacity-100 transition-opacity duration-300"
                                    style={{
                                        background: `linear-gradient(45deg, ${gradientColors.join(', ')})`,
                                        backgroundSize: '200% 200%',
                                        animation: 'gradient 5s ease infinite',
                                    }}
                                ></span>
                            </button>
                            {showModal && (
                            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                                <div className="bg-white p-6 text-left rounded-lg shadow-xl max-w-md w-full">
                                    <h2 className="text-2xl font-bold mb-4">Terms and Conditions</h2>
                                    <p className="mb-4">
                                        By submitting my information I agree to the <a href="https://wct.live/app/41604/theMoonTeller/terms" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">Official Rules</a> and acknowledge that I have reviewed and agree to the <a href="https://wct.live/app/41604/theMoonTeller/privacy" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">Privacy Policy</a> and <a href="https://wct.live/app/41604/theMoonTeller/terms" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">Terms of Use</a>; and I further agree to receive updates and marketing messages from Topsify.
                                    </p>
                                    <label className="flex items-center mb-4">
                                        <input
                                            type="checkbox"
                                            checked={termsAccepted}
                                            onChange={(e) => setTermsAccepted(e.target.checked)}
                                            className="mr-2"
                                        />
                                        <span>I agree that I have read and accept the <a href="https://wminewmedia.com/terms-of-use/" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">Terms of Use</a> and Terms and Conditions.</span>
                                    </label>
                                    <div className="flex justify-end space-x-4">
                                        <button
                                            onClick={handleSubmit}
                                            disabled={!termsAccepted}
                                            className={`py-2 px-4 rounded ${termsAccepted ? 'bg-blue-600 text-white hover:bg-blue-700' : 'bg-gray-300 text-gray-500 cursor-not-allowed'}`}
                                        >
                                            Submit
                                        </button>
                                        <button
                                            onClick={() => setShowModal(false)}
                                            className="py-2 px-4 bg-gray-200 text-gray-800 rounded hover:bg-gray-300"
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                                </div>
                            )}
                        </>
                    ) : (
                        <button
                            className="block w-full py-3 px-6 bg-[#0a0a0a] text-white rounded-md text-sm sm:text-lg font-semibold cursor-pointer shadow-md transition-all duration-300 relative group overflow-hidden"
                            onClick={() => navigate('/questions')}
                        >
                            <span className="relative z-10">Discover Your Moon Phase</span>
                            <span
                                className="absolute inset-0 opacity-0 group-hover:opacity-100 transition-opacity duration-300"
                                style={{
                                    background: `linear-gradient(45deg, ${gradientColors.join(', ')})`,
                                    backgroundSize: '200% 200%',
                                    animation: 'gradient 5s ease infinite',
                                }}
                            ></span>
                        </button>
                    )}
                </div>
                <div className="w-4/5 max-w-[512px] aspect-w-9 aspect-h-16 border-2 border-solid border-[#0a0a0a] flex justify-center items-center mt-12">
                    <img src="./Assets/Images/sampleOutput.png" alt="Sample Output" className="w-full h-full object-contain" />
                </div>
            </main>
            <Footer />
            {showSignUp && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
                    <div className="relative bg-white rounded-lg shadow-xl max-w-md w-full mx-auto overflow-hidden">
                        <div className="bg-gray-100 px-6 py-4 border-b border-gray-200 flex justify-between items-center">
                            <h2 className="text-2xl font-semibold text-gray-800">Sign Up</h2>
                            <button
                                onClick={() => setShowSignUp(false)}
                                className="text-gray-500 hover:text-gray-700 transition-colors duration-200"
                                aria-label="Close"
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                        </div>
                        <div className="p-6">
                            <SignUpComponent
                                newsletterId="14077858"
                                onSignUpSuccess={handleSignUpSuccess}
                            />
                        </div>
                    </div>
                </div>
            )}

            <style jsx>{`
                @keyframes gradient {
                    0% { background-position: 0% 50%; }
                    50% { background-position: 100% 50%; }
                    100% { background-position: 0% 50%; }
                }
            `}</style>
        </div>
    );
}

export default Landing;
