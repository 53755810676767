import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ isSignedUp, children }) => {
  const isSignedUpNew = localStorage.getItem('userSignedUp') === 'true';

  if (!isSignedUpNew) {
    // Redirect them to the home page if not signed up
    return <Navigate to="/" replace />;
  }

  return children;
};

export default ProtectedRoute;