import React from 'react';

function Slide({ question, response, onResponseChange }) {
    const moonPhases = [
        ['#7EA4FF', '#A3C8FA', '#F7AB8A'],
        ['#F8D808', '#E66D7B', '#282962'],
        ['#FDA2DA', '#7C47DB', '#0A26AC'],
        ['#00B7FF', '#FEF439', '#FF3030'],
        ['#FC4084', '#FDBFD6', '#395D93'],
        ['#FFDBD2', '#DC8169', '#134787'],
        ['#30B1C9', '#FDDEE1', '#F9C6C8'],
        ['#F7C6FA', '#96B9EF', '#AFF4CB']
    ];

    // Choose a random moon phase for the gradient
    const randomPhase = moonPhases[Math.floor(Math.random() * moonPhases.length)];

    const handleOptionChange = (option) => {
        if (question.multiSelect) {
            const newResponse = response.includes(option)
                ? response.filter((item) => item !== option)
                : [...response, option];
            onResponseChange(newResponse);
        } else {
            onResponseChange(option);
        }
    };

    const handleAgeChange = (e) => {
        const age = parseInt(e.target.value, 10);
        if (!isNaN(age) && age >= question.min && age <= question.max) {
            onResponseChange(age);
        }
    };

    return (
        <div className="w-full md:w-3/5">
            <h2 className="text-xl md:text-2xl mb-4">Question {question.index + 1} of {question.total}</h2>
            <h3 className="text-xl md:text-3xl mb-6">{question.question}</h3>
            {question.type === 'open' && (
                <input
                    type="text"
                    className="w-full p-2 border border-[#0a0a0a] rounded-lg bg-white bg-opacity-50 focus:outline-none focus:ring-2 focus:ring-blue-500 text-xl"
                    placeholder={question.placeholder}
                    value={response}
                    onChange={(e) => onResponseChange(e.target.value)}
                />
            )}
            {question.type === 'multiple' && (
                <div className="flex flex-col gap-4">
                    {question.options.map((option, index) => (
                        <label key={index} className="flex items-center">
                            <input
                                type={question.multiSelect ? 'checkbox' : 'radio'}
                                className="hidden"
                                checked={question.multiSelect ? response.includes(option) : response === option}
                                onChange={() => handleOptionChange(option)}
                            />
                            <div 
                                className={`w-full p-2 border border-gray-300 rounded-lg cursor-pointer text-sm md:text-xl ${
                                    question.multiSelect
                                        ? (response.includes(option) ? 'text-white' : 'bg-white bg-opacity-50')
                                        : (response === option ? 'text-white' : 'bg-white bg-opacity-50')
                                } duration-300 transition-all hover:bg-opacity-75`}
                                style={{
                                    background: question.multiSelect
                                        ? (response.includes(option) ? `linear-gradient(45deg, ${randomPhase.join(', ')})` : '')
                                        : (response === option ? `linear-gradient(45deg, ${randomPhase.join(', ')})` : '')
                                }}
                            >
                                {option}
                            </div>
                        </label>
                    ))}
                </div>
            )}
            {question.type === 'age' && (
                <input
                    type="number"
                    min={question.min}
                    max={question.max}
                    value={response || ''}
                    onChange={handleAgeChange}
                    className="w-full p-4 border border-[#0a0a0a] rounded-lg bg-white bg-opacity-50 focus:outline-none focus:ring-2 focus:ring-blue-500 text-xl"
                    placeholder="Enter your age"
                />
            )}
        </div>
    );
}

export default Slide;